import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      width={21}
      height={21}
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M13.063 10L19.5 3.625l1.313-1.313c.187-.187.187-.5 0-.75L19.438.188c-.25-.187-.563-.187-.75 0L11 7.938 3.25.188C3.062 0 2.75 0 2.5.188L1.125 1.563c-.188.25-.188.563 0 .75L8.875 10l-7.75 7.75c-.188.188-.188.5 0 .75L2.5 19.875c.25.188.563.188.75 0l7.75-7.75 6.375 6.438 1.313 1.312c.187.188.5.188.75 0l1.375-1.375c.187-.25.187-.563 0-.75L13.063 10z"
        fill={props.fill || "#949494"}
      />
    </Svg>
  )
}

export default SvgComponent
